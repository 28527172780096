/* You can add global styles to this file, and also import other style files */
@use "./themes/dark.theme.scss";
@use "./themes/light.theme.scss";
// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap");
@import "nouislider/dist/nouislider.min.css";

// Google poppins font
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/fonts/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Material Icon
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/material-icon.woff2") format("woff2");
}

body {
  background-color: var(--background-color);
  overflow-x: hidden;
  overflow-y: hidden;
}

.highcharts-tooltip-container {
  z-index: 999999 !important;
}

.global-text-class {
  color: var(--text-color);
}

.global-icon-class {
  color: var(--icon-color);
}

.global-svg-class {
  fill: var(--text-color);
}

.popUp_class {
  background-color: red !important;
  color: red !important;
}

.material-icons,
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
}

.bold-icon {
  font-weight: 700;
}

html {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  padding: 0;
  height: calc(100% - 64px);
}

.cdk-overlay-dark-backdrop {
  background: rgba(4, 1, 48, 0.7);
}

// Upload image
.invert {
  filter: var(--invert-filter);
}

.zone-content {
  .mat-expansion-panel-body {
    padding: 0px;
  }
}

// Paginator
.paginator {
  margin-top: 5px;

  .mat-mdc-form-field-infix {
    text-align: center;
    padding-right: 5px;
  }
}

// Active link color
.active-link {
  color: #3e72ff !important;
  font-weight: 700 !important;

  .mat-mdc-select-value {
    color: #3e72ff !important;
    font-weight: 700 !important;
  }
}

.active-opt {
  color: #3e72ff !important;
  font-weight: 600;
}

.device-list-modal {
  max-width: none !important;
  width: 100% !important;

  .mat-mdc-dialog-container {
    padding: 0 !important;
  }

  .mat-mdc-dialog-content {
    margin: 0px !important;
    padding: 0px !important;
    max-height: none !important;
  }
}

// Detail view N3 device - this should go to component's scss file
.paths {
  padding-right: 20px;
  padding-left: 20px;

  .path-title {
    font-size: 14px;
    color: #464646;
    font-weight: 600;
  }

  .path-name {
    font-size: 13px;
    color: #464646;
    text-align: center;
    font-weight: 400;
  }
}

.ngx-file-drop__content {
  flex-direction: column;
}

// global styles for spinner
.spinner {
  margin: 0 auto;
  top: 50px;
}

.date-spinner {
  top: 10px;
}

// global class for hiding elements during development
// todo - check all places where it is used before any deployment!
.hide_during_development {
  display: none !important;
}

// Disabled checkbox animation on Device's sidebar
.bulk-selector .mat-ripple {
  display: none;
}

.parent-device .mat-ripple {
  display: none;
}

.error-background {
  background-color: #f44336;
  color: #ffffff;
  --mdc-snackbar-container-color: #f44336 !important;
  --mdc-snackbar-supporting-text-color: #ffffff !important;
  text-align: center;
}

.t-left {
  text-align: left;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

// Initial page loading spinner
.loader-wrapper-small {
  width: 100%;
  height: 80px;
  overflow: hidden;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader-small,
.loader-small::after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.loader,
.loader-small {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(62, 73, 224, 0.2);
  border-right: 1.1em solid rgba(62, 73, 224, 0.2);
  border-bottom: 1.1em solid rgba(62, 73, 224, 0.2);
  border-left: 1.1em solid #3e49e0;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.loader-small {
  margin: 5px auto;
  border-top: 5px solid rgba(62, 73, 224, 0.2);
  border-right: 5px solid rgba(62, 73, 224, 0.2);
  border-bottom: 5px solid rgba(62, 73, 224, 0.2);
  border-left: 5px solid #3e49e0;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Spacer classes
.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.tooltip-linebreak {
  white-space: pre-line;
}

.line-chart-tooltip {
  width: 300px !important;
}

.swal2-icon {
  width: 50px !important;
  height: 50px !important;
  margin: 12px auto 6px !important;

  .swal2-icon-content {
    font-size: 32px !important;
  }
}

.swal2-title {
  font-size: 16px !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-deny {
  background-color: #3e72ff !important;
}

.mat-mdc-tooltip-trigger {
  touch-action: auto !important;
}

.status-good {
  color: #65ba47 !important;
}

.status-middle {
  color: rgb(220, 180, 0) !important;
}

.status-bad {
  color: rgb(236, 42, 35) !important;
}

.status-error {
  color: rgba(41, 69, 79, 0.4) !important;
}

.status-pending {
  color: rgba(127, 152, 161, 0.4) !important;
}

.status-unknown {
  color: rgba(66, 160, 194, 0.4) !important;
}

.gm-style iframe + div {
  border: none !important;
  outline: none !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.info-window {
  white-space: pre-line;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: var(--background-color);
}

.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  border: 2px solid var(--light-background-color);
}

.context-menu-button {
  height: 100%;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #3e72ff;
  outline: none;
  background: var(--light-background-color);
  color: #3e72ff;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: #3e72ff;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
}

.info-comment {
  color: var(--text-color);
  font-size: 11px;
  font-weight: 500;
}

.close-info {
  margin: 0;
  text-align: right;
  font-size: 16px;
}

.info-green {
  color: #00af00 !important;
}

.info-yellow {
  color: #ffbb00 !important;
}

.info-red {
  color: #ff0000 !important;
}

.info-blue {
  color: #3e72ff;
}

.info-gray {
  color: var(--text-color);
}

.selected-marker {
  width: 16px;
  height: 16px;
  position: relative;
  background-color: rgba(0, 255, 0, 0.3);
  border-radius: 50%;
  animation: animate-marker 1s linear infinite;
}

@keyframes animate-marker {
  0% {
    box-shadow: 0px 0px 0px 0px rgb(0, 255, 0, 1);
  }

  100% {
    box-shadow: 0px 0px 0px 10px rgba(62, 114, 255, 0);
  }
}

.selected-marker-nm-marker,
.selected-marker-nm-edgenode,
.selected-marker-nm-vertexnode {
  position: relative;
  border-radius: 50%;
}

.selected-marker-nm-marker {
  width: 20px;
  height: 20px;
  background: rgba(0, 255, 0, 0.5);
  animation: animate-nm-marker 1s linear infinite;
}

@keyframes animate-nm-marker {
  0% {
    box-shadow:
      0 0 0 0 rgba(0, 255, 0, 1),
      0 0 0 0 rgba(0, 255, 0, 1);
  }

  100% {
    box-shadow:
      0 0 0 0 rgba(255, 0, 0, 0),
      0 0 0 10px rgba(255, 0, 0, 0);
  }
}

.selected-marker-nm-edgenode,
.selected-marker-nm-vertexnode {
  width: 16px;
  height: 16px;
  background: rgba(255, 165, 0, 1);
  animation: animate-nm-node 1s linear infinite;
}

.selected-marker-nm-edgenode {
  background: rgba(255, 80, 0, 1);
}

@keyframes animate-nm-node {
  0% {
    box-shadow:
      0 0 0 0 rgba(255, 165, 0, 1),
      0 0 0 0 rgba(255, 165, 0, 1);
  }

  100% {
    box-shadow:
      0 0 0 0 rgba(255, 165, 0, 0),
      0 0 0 8px rgba(255, 165, 0, 0);
  }
}

.marker-label {
  width: 100%;
  white-space: pre;
  text-align: left;
  background-color: #ffffffae;
}

.latest-date-not-today {
  background-color: rgba(255, 155, 0, 0.3) !important;
}

.current-date-not-today {
  border: 1px solid #ff9b00 !important;
}

/* --- notification styles ---*/
.notif-repl-btn {
  font:
    14px Poppins,
    sans-serif;
  color: #ffffff;
  background-color: #3885ff;
  border: none;
  padding: 8px;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #488dfd;
  }
}

.notif-repl-link {
  font:
    14px Poppins,
    sans-serif;
  color: #3e72ff;

  &:hover {
    color: #488dfd;
  }
}

/* --- end --- */

/* --- guided tour --- */
.tour-step {
  max-width: 450px !important;
  min-width: 250px !important;
  width: 450px !important;
  max-height: 350px !important;
  background-color: var(--light-background-color) !important;
  color: var(--text-color);
  border: 2px solid #00000040 !important;
  border-radius: 8px !important;

  .shepherd-content {
    background-color: var(--light-background-color) !important;
    color: var(--text-color);
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: inherit !important;

    .shepherd-header {
      background-color: var(--light-background-color) !important;
      color: var(--text-color);
      flex: 0 0 50px !important;
      padding: 0px 12px !important;
      border-radius: inherit !important;

      .shepherd-title {
        font-weight: 500;
        color: var(--text-color);
        background-color: var(--light-background-color) !important;
      }
    }

    .shepherd-text {
      background-color: var(--light-background-color) !important;
      color: var(--text-color);
      min-height: 50px !important;
      max-height: 250px !important;
      overflow-y: auto;
      border-top: 1px solid #00000040;
      border-bottom: 1px solid #00000040;
    }

    .shepherd-footer {
      flex: 0 0 50px !important;
      padding: 8px 12px !important;
      gap: 12px;

      .shepherd-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
        width: 80px !important;
        padding: 0 !important;
        transition: none;
        font-family: "Poppins", sans-serif !important;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .tour-step {
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.deactivate-div {
  pointer-events: none;
  opacity: 0.5;
}

.deactivate-mask {
  pointer-events: none;
}

.tour-mat-icon {
  font-size: 20px;
  vertical-align: text-bottom;
}

.tour-step-available {
  cursor: help !important;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" fill="blue"><path d="M431 726q1-72 16.5-105t58.5-72q42-38 64.5-70.5T593 409q0-45-30-75t-84-30q-52 0-80 29.5T358 395l-84-37q22-59 74.5-100.5T479 216q100 0 154 55.5T687 405q0 48-20.5 87T601 574q-49 47-59 72t-11 80H431Zm48 250q-29 0-49.5-20.5T409 906q0-29 20.5-49.5T479 836q29 0 49.5 20.5T549 906q0 29-20.5 49.5T479 976Z"/></svg>');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 10px;

  &:hover {
    border: 2px dotted rgba(255, 0, 0, 0.5) !important;
    border-radius: 8px;
  }
}

.tour-step-selected {
  pointer-events: none;
  border: none;
}

.tour-step-selected-interactive {
  pointer-events: none;
  border: 2px dotted rgba(255, 0, 0, 0.5) !important;
  border-radius: 8px;
}

.pad-left-10 {
  padding-left: 10px;
}

/* --- end --- */

/* --- interactive help --- */
.body-container {
  font-size: 15px;

  p img {
    vertical-align: bottom;
    width: 28px;
    height: 28px;
  }

  span.menuitem {
    font-weight: bold;
    color: #3e72ff;
  }

  p.subheading {
    font-weight: bold;
    page-break-after: avoid;
    font-size: 1.1em;
    margin-top: 8pt;
  }

  span.bold {
    font-weight: bold;
  }

  li {
    page-break-inside: avoid;
  }

  li > p {
    margin-top: 4pt;
    color: #000000;
    margin-bottom: 6pt;
  }

  ul {
    list-style-type: square;
    margin-top: -8px;
    margin-bottom: 22px;
  }

  ul.wasser > li {
    color: #3e72ff;
    font-size: 1.6em;
  }

  ul > li > p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.6em;
    color: var(--text-color);
  }
}

/* --- end --- */

/* --- perfect-scrollbar --- */
.perfect-scroll-style {
  color: var(--text-color);
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden !important;
}

/* --- end --- */

/* --- @angular/flex-layout replacement classes --- */
.fx_fill {
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.fx_flex_grow {
  flex: 1 1 100%;
}

// column classes
.fx_layout_col {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.fx_layout_col_align_center {
  justify-content: center;
}

.fx_layout_col_align_space_between {
  justify-content: space-between;
}

.fx_flex_col_10 {
  flex: 1 1 10%;
  max-height: 10%;
}

.fx_flex_col_20 {
  flex: 1 1 20%;
  max-height: 20%;
}

.fx_flex_col_48 {
  flex: 1 1 48%;
  max-height: 48%;
}

.fx_flex_col_50 {
  flex: 1 1 50%;
  max-height: 50%;
}

.fx_flex_col_52 {
  flex: 1 1 52%;
  max-height: 52%;
}

.fx_flex_col_65 {
  flex: 1 1 65%;
  max-height: 65%;
}

.fx_flex_col_75 {
  flex: 1 1 75%;
  max-height: 75%;
}

.fx_flex_col_80 {
  flex: 1 1 80%;
  max-height: 80%;
}

.fx_flex_col_100 {
  flex: 1 1 100%;
  max-height: 100%;
}

.fx_flex_col_32px {
  flex: 1 1 32px;
  max-height: 32px;
}

.fx_flex_col_36px {
  flex: 1 1 36px;
  max-height: 36px;
}

.fx_flex_col_40px {
  flex: 1 1 40px;
  max-height: 40px;
}

.fx_flex_col_45px {
  flex: 1 1 45px;
  max-height: 45px;
}

.fx_flex_col_60px {
  flex: 1 1 60px;
  max-height: 60px;
}

.fx_flex_col_250px {
  flex: 1 1 250px;
  max-height: 250px;
}

// row classes
.fx_layout_row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.fx_layout_row_align_center {
  align-items: center;
  align-content: center;
}

.fx_layout_row_align_end {
  align-items: flex-end;
  align-content: flex-end;
}

.fx_layout_row_align_space_between {
  align-items: space-between;
  align-content: space-between;
}

.fx_layout_row_align_stretch {
  max-width: 100%;
  max-height: 100%;
}

.fx_flex_row_10 {
  flex: 1 1 10%;
  max-width: 10%;
}

.fx_flex_row_15 {
  flex: 1 1 15%;
  max-width: 15%;
}

.fx_flex_row_20 {
  flex: 1 1 20%;
  max-width: 20%;
}

.fx_flex_row_25 {
  flex: 1 1 25%;
  max-width: 25%;
}

.fx_flex_row_28 {
  flex: 1 1 28%;
  max-width: 28%;
}

.fx_flex_row_33 {
  flex: 1 1 33%;
  max-width: 33%;
}

.fx_flex_row_35 {
  flex: 1 1 35%;
  max-width: 35%;
}

.fx_flex_row_48 {
  flex: 1 1 48%;
  max-width: 48%;
}

.fx_flex_row_50 {
  flex: 1 1 50%;
  max-width: 50%;
}

.fx_flex_row_65 {
  flex: 1 1 65%;
  max-width: 65%;
}

.fx_flex_row_80 {
  flex: 1 1 80%;
  max-width: 80%;
}

.fx_flex_row_85 {
  flex: 1 1 85%;
  max-width: 85%;
}

.fx_flex_row_90 {
  flex: 1 1 90%;
  max-width: 90%;
}

.fx_flex_row_100 {
  flex: 1 1 100%;
  max-width: 100%;
}

.fx_flex_row_50_100 {
  flex: 1 1 50%;
  max-width: 50%;
}

.fx_flex_row_15px {
  flex: 1 1 15px;
  max-width: 15px;
}

.fx_flex_row_24px {
  flex: 1 1 24px;
  max-width: 24px;
}

.fx_flex_row_40px {
  flex: 1 1 40px;
  max-width: 40px;
}

.fx_flex_row_45px {
  flex: 1 1 45px;
  max-width: 45px;
}

.fx_flex_row_48px {
  flex: 1 1 48px;
  max-width: 48px;
}

.fx_flex_row_50px {
  flex: 1 1 50px;
  max-width: 50px;
}

.fx_flex_row_60px {
  flex: 1 1 60px;
  max-width: 60px;
}

.fx_flex_row_90px {
  flex: 1 1 90px;
  max-width: 90px;
}

.fx_flex_row_100px {
  flex: 1 1 100px;
  max-width: 100px;
}

.fx_flex_row_150px {
  flex: 1 1 150px;
  max-width: 150px;
}

.fx_flex_row_165px {
  flex: 1 1 165px;
  max-width: 165px;
}

.fx_flex_row_175px {
  flex: 1 1 175px;
  max-width: 175px;
}

.fx_flex_row_250px {
  flex: 1 0 250px;
  max-width: 250px;
}

.fx_flex_row_280px {
  flex: 1 0 280px;
  max-width: 280px;
}

.fx_flex_row_320px {
  flex: 1 0 320px;
  max-width: 320px;
}

.fx_flex_row_425px {
  flex: 1 0 425px;
  max-width: 425px;
}

// gap classes
.fx_layout_gap_10px {
  gap: 10px;
}

.fx_layout_gap_20px {
  gap: 20px;
}

.fx_layout_gap_30px {
  gap: 30px;
}

// show/hide
.fx_show {
  display: flex !important;
}

.fx_show_sm {
  display: none;
}

.fx_hide {
  display: none !important;
}

.fx_hide_xs {
  display: flex;
}

.fx_hide_sm {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .fx_hide_xs {
    display: none !important;
  }

  .fx_flex_row_50_100 {
    flex: 1 1 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 960px) {
  .fx_show_sm {
    display: flex !important;
  }

  .fx_hide_sm {
    display: none !important;
  }
}

/* --- end --- */

/* --- legacy migration --- */
.mat-mdc-form-field {
  font-size: 16px !important;

  mat-label {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .mdc-text-field {
    padding: 0 !important;
    align-items: center;
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
    font-weight: 600 !important;
  }
}

.mdc-form-field {
  label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #748296 !important;
  }
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #3e72ff !important;
}

.mat-mdc-menu-panel {
  background-color: var(--background-color) !important;
}

.mat-mdc-menu-select {
  border-radius: 16px;
  width: 100%;
  background-color: #f5f7fc;
  font-size: 14px !important;
  font-weight: 600;

  &:hover {
    background: #ebedf2;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-infix {
    border-top: 0px !important;
    padding: 16px 10px 16px 10px !important;
    width: auto;
  }

  .mat-mdc-select-arrow-wrapper {
    padding-right: 10px !important;
  }
}

.mat-mdc-form-field-flex {
  align-self: center;
}

.mat-mdc-form-field-type-mat-input .mdc-line-ripple {
  display: none;
}

// .mat-mdc-select-value {
//   &:not([disabled]) {
//     color: #3e72ff !important;
//     font-weight: 600 !important;
//   }
// }

app-dashboard-setting,
app-export-setting {
  .mat-mdc-radio-button label {
    cursor: pointer !important;
  }
}

app-flow-direction {
  .mat-mdc-form-field {
    .mdc-line-ripple {
      display: flex !important;
    }
  }
}

app-favorite,
app-favorite-view {
  .mdc-text-field .mdc-text-field__input {
    font-size: 13px !important;
  }
}

app-login,
app-new-pw,
app-reset-pw,
app-add-zone,
app-edit-zone,
app-edit-user,
app-invite-user {
  .mdc-line-ripple {
    display: flex !important;
  }
}

/* --- no-ui-slider --- */
.no-ui-slider-slider {
  height: calc(100% - 10px);
  margin-top: 13px;
  cursor: pointer;

  .noUi-vertical {
    height: 100%;
    width: 2px;
  }

  .noUi-connects {
    width: 2px;
    background-color: #3885ff;
  }

  .noUi-tooltip {
    display: none;
  }

  .noUi-active .noUi-tooltip {
    display: block;
    background: #3885ff;
    color: white;
    border-radius: 50%;
    height: 32px;
    width: 35px;
    font-size: 14px;
  }

  .noUi-vertical .noUi-handle {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    right: -8px;
    bottom: -6px;
    background-color: #3885ff;
    box-shadow: none;
    cursor: pointer;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

.slider-disabled {
  pointer-events: none;

  .noUi-connects,
  .noUi-handle {
    background-color: #c5c5c5 !important;
  }
}

.currency-menu-list {
  width: 150px !important;
}

// Custom web components styles

// perfect scrollbar without right offset
.ps__thumb-y {
  right: 0px !important;
}

// mat-accordion with customized padding
.low-padding-accordion {
  mat-expansion-panel {
    mat-expansion-panel-header,
    .mat-expansion-panel-body {
      padding: 0px 16px 0px 8px !important;
    }
  }
}

// mat-accordion with sticky headers - not combinable with allow-sticky-content
.sticky-accordion {
  .mat-expansion-panel {
    overflow: inherit !important;
  }

  .mat-expansion-panel-body {
    overflow: hidden;
  }

  .mat-expansion-panel-header.mat-expanded {
    top: 0;
    z-index: 1000;
    position: -webkit-sticky;
    position: sticky;
    background-color: #ffffff;
  }
}

// mat-expansion-panel in mat-accordion with sticky content - not combinable with sticky-accordion
mat-expansion-panel.allow-sticky-content {
  overflow: unset;
}

mat-expansion-panel.allow-sticky-content .mat-expansion-panel-content {
  overflow: unset;
  max-width: 100%;
}

mat-expansion-panel.allow-sticky-content .mat-expansion-panel-content.ng-animating {
  overflow: hidden;
}

// mat-tab-group with sticky content
mat-tab-group.allow-sticky-content {
  overflow: unset;
  box-shadow: -3px 0px 0px 0px #3e72ff;
  padding-left: 2px;
  width: calc(100% - 2px);
}

mat-tab-group.allow-sticky-content .mat-mdc-tab-body-wrapper {
  overflow: unset;
}

mat-tab-group.allow-sticky-content .mat-mdc-tab-body-active {
  overflow: unset;
  max-width: 100%;
}

mat-tab-group.allow-sticky-content .mat-mdc-tab-body-content {
  overflow: unset;
}

// mat-tab-group with sticky headers - needs allow-sticky-content if inside mat-accordion
.sticky-tab-group,
.sticky-tab-group-level2 {
  mat-tab-header {
    top: 0;
    z-index: 1000;
    position: -webkit-sticky;
    position: sticky;
    background-color: #ffffff;

    .mdc-tab {
      height: 32px !important;
    }
  }

  box-shadow: -3px 0px 0px 0px #3e72ff;
  padding-left: 2px;
  width: calc(100% - 2px);

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: #3e72ff !important;
    font-weight: 500 !important;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: #3e72ff !important;
  }
}

.sticky-tab-group-level2 {
  mat-tab-header {
    top: 32px;
  }

  box-shadow: -3px 0px 0px 0px #ffbb00 !important;

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: #ffbb00 !important;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: #ffbb00 !important;
  }
}

// mat-tab with hidden header
.invisible-tab-headers {
  mat-tab-header {
    display: none;
  }
}

// mat-tab-body additional fading animation
mat-tab-group.allow-sticky-content mat-tab-body {
  animation: fade-out 0.5s;
  opacity: 0;
}

mat-tab-group.allow-sticky-content mat-tab-body.mat-mdc-tab-body-active {
  animation: fade-in 0.5s;
  opacity: 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

// sticky element
.sticky-element,
.sticky-element-level2 {
  top: 0;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  background-color: #ffffff;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #3e72ff !important;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 2px solid #3e72ff;
}

.sticky-element-level2 {
  top: 32px;
  color: #ffbb00 !important;
  border-bottom: 2px solid #ffbb00 !important;
}

/* --- button styles ---*/
.round-header-btn,
.round-header-btn-disabled,
.round-btn,
.round-btn-disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(154, 158, 169, 0.5);
  color: #3e72ff;
  fill: #3e72ff;
  cursor: pointer;

  .small-mat-icon {
    font-size: 20px;
    line-height: 24px;
  }

  .mix-ico {
    width: 12px;
  }

  .sum-ico {
    width: 18px;
  }

  &:hover:enabled {
    fill: #ffffff;
    color: #ffffff;
    background-color: #3e72ff;
    outline: 2px solid #ffffff;
  }

  &:disabled {
    color: #d0d0d0;
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

.round-header-btn {
  background-color: #3e72ff;
  color: #ffffff;
  fill: #ffffff;
  box-shadow:
    -3px -3px 8px #507bf0,
    3px 3px 8px #2761ff;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.round-btn-active {
  background-color: #3e72ff !important;
  fill: #ffffff !important;
}

.round-header-btn-disabled,
.round-btn-disabled {
  color: #d0d0d0;
  cursor: default;
}

.center-btn {
  margin: auto;
}

.chevron-ico {
  float: right;
  color: #1f2d3d;
  cursor: pointer;
  font-size: 17px;
  border-radius: 50%;

  &:hover {
    background-color: #3e72ff;
    color: #ffffff;
  }
}

/* --- end ---*/

/* --- google advanced marker styles ---*/
.yNHHyP-marker-view:has(.gm-advanced-marker-icon) {
  top: 8px !important;
}

.yNHHyP-marker-view:has(.gm-advanced-marker-icon):has(.gm-advanced-marker-label) {
  top: 15px !important;
}

.yNHHyP-marker-view:has(.gm-advanced-marker-icon-big) {
  top: 12px !important;
}

.yNHHyP-marker-view:has(.gm-advanced-marker-icon-big):has(.gm-advanced-marker-label) {
  top: 19px !important;
}

.gm-advanced-marker-icon,
.gm-advanced-marker-icon-big {
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gm-advanced-marker-icon-big {
  width: 24px;
  height: 24px;
}

.gm-advanced-marker-image {
  width: 100%;
  height: auto;
}

.gm-advanced-marker-label {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.gma-svg-icon,
.gma-svg-icon-big {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  min-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.gma-svg-icon-big {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-height: 20px;
}

.gma-svg-border-blue {
  outline: 2px solid #3e72ff;
}

.gma-svg-border-red {
  outline: 2px solid #ff6752;
}

.gma-svg-border-lightblue {
  outline: 2px solid #8dc2e0;
}

.gma-svg-border-darkred {
  outline: 2px solid #ff0000 !important;
}

.gma-svg-border-darkyellow {
  outline: 2px solid #ffbf00 !important;
}

.gma-svg-background-red {
  background-color: #ff6752;
}

.gma-svg-background-yellow {
  background-color: #f6f054;
}

.gma-svg-background-green {
  background-color: #a7f879;
}

.gma-svg-background-white {
  background-color: #ffffff;
}

.gma-svg-background-gray {
  background-color: #e3dede;
}

.gma-svg-background-mixed {
  background: linear-gradient(135deg, #e3dede 0%, #e3dede 50%, #ff6752 50%, #ff6752 100%);
  opacity: 0.5;
}

.gm-advanced-marker-svg {
  position: relative;
  top: 34.5px;
}

.gm-advanced-marker-svg-small {
  position: relative;
  top: 18.5px;
}

// anchor point of advanced info window
.gm-style-iw-t:has(.info-window) {
  bottom: 20px !important;
}

.gm-style-iw-t:has(.info-window-big) {
  bottom: 24px !important;
}

/* --- end ---*/

// For mat slider vertical label display
.profile-list .mat-mdc-slider .mdc-slider__value-indicator-text {
  transform: rotate(90deg) !important;
}

.audio-frequency-section .mat-mdc-slider .mdc-slider__value-indicator-text {
  transform: rotate(90deg) !important;
}
