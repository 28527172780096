@use "@angular/material" as mat;

$md-primary: (
  50: #eaeffa,
  100: #bedfff,
  200: #91ccff,
  300: #2d95ff,
  400: #3fa6ff,
  500: #3885ff,
  600: #415eec,
  700: #3e72ff,
  800: #415eec,
  900: #00469b,
  A100: #c7f6ff,
  A200: #94eeff,
  A400: #61e5ff,
  A700: #48e1ff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

$general-typography: mat.m2-define-typography-config(
  $font-family: '"Poppins", sans-serif',
  $body-1: mat.m2-define-typography-level(14px, 24px, $letter-spacing: 0em),
  $body-2: mat.m2-define-typography-level(14px, 24px, 600, $letter-spacing: 0em),
  $button: mat.m2-define-typography-level(14px, 24px, $letter-spacing: 0em)
);

$sb-theme-primary: mat.m2-define-palette($md-primary);
$sb-theme-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$sb-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

$sb-light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $sb-theme-primary,
      accent: $sb-theme-accent,
      warn: $sb-theme-warn
    ),
    typography: $general-typography
  )
);

@include mat.all-component-themes($sb-light-theme);

.lightTheme {
  --logo-background-color: #abcbff;
  --background-color: #f5f7fc;
  --dark-background-color: #eaeef8;
  --light-background-color: #ffffff;
  --background-overlay: transparent;
  --text-color: #212121;
  --light-text-color: #7e7e7e;
  --icon-color: #3c4858;
  --light-icon-color: #bdbdbd;
  --select-color: #d0ddfd;
  --hover-color: #e1e1e1;
  --table-background: #f2f6ff;
  --table-blue-background: #ecf4fa;
  --table-grey-background: #eeeeee;
  --light-table-background: #f9fafc;
  --link-color: #3885ff;
  --card-background-color: var(--light-background-color);
  --card-active-background-color: var(--background-color);
  --backdrop-color: linear-gradient(349.75deg, rgba(62, 114, 255, 0.72) 43.51%, rgba(255, 255, 255, 0.368) 152.13%);
  --login-logo-gradient: transparent;
  --invert-filter: invert(0%);
}
