@use "@angular/material" as mat;

$md-primary: (
  50: #eaeffa,
  100: #bedfff,
  200: #91ccff,
  300: #2d95ff,
  400: #3fa6ff,
  500: #3885ff,
  600: #415eec,
  700: #3e72ff,
  800: #415eec,
  900: #00469b,
  A100: #c7f6ff,
  A200: #94eeff,
  A400: #61e5ff,
  A700: #48e1ff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

$general-typography: mat.m2-define-typography-config(
  $font-family: '"Poppins", sans-serif',
  $body-1: mat.m2-define-typography-level(14px, 24px, $letter-spacing: 0em),
  $body-2: mat.m2-define-typography-level(14px, 24px, 600, $letter-spacing: 0em),
  $button: mat.m2-define-typography-level(14px, 24px, $letter-spacing: 0em)
);

$sb-theme-primary: mat.m2-define-palette($md-primary);
$sb-theme-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$sb-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

$sb-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $sb-theme-primary,
      accent: $sb-theme-accent,
      warn: $sb-theme-warn
    ),
    typography: $general-typography
  )
);

.darkTheme {
  --logo-background-color: transparent;
  --background-color: #212121;
  --light-background-color: #424242;
  --dark-background-color: var(--background-color);
  --background-overlay: rgba(0, 0, 0, 0.7);
  --text-color: #f5f5f5;
  --light-text-color: #dfdfdf;
  --icon-color: #ffffff;
  --light-icon-color: #afafaf;
  --select-color: #3b3b3b;
  --hover-color: #323232;
  --table-background: #313131;
  --table-blue-background: #1e2738;
  --table-grey-background: #383838;
  --light-table-background: #222222;
  --link-color: #3885ff;
  --card-background-color: var(--background-color);
  --card-active-background-color: var(--light-background-color);
  --backdrop-color: linear-gradient(349.75deg, rgba(20, 35, 77, 0.72) 43.51%, rgba(102, 102, 102, 0.368) 152.13%);
  --login-logo-gradient: linear-gradient(to right, rgba(255, 255, 255, 0.8), transparent);
  --invert-filter: invert(1);
  @include mat.all-component-colors($sb-dark-theme);
}
